<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="mb-3">
          <h3>
            คลัง {{ inputsnode.totalRows }} ที่
          </h3>
        </div>
        <div class="row justify-content-between">
          <div class="col-md-6 col-xl-2 mb-1">
            <b-button
              block
              variant="success"
              style="height: 38px;"
              @click="openModal"
            >
              เพิ่มคลังสินค้า
            </b-button>
          </div>
          <div class="col-md-6 col-xl-3  mb-1">
            <b-form-group style="margin-bottom: 0rem;">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="ค้นหา"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
        </div>

        <b-table
          striped
          hover
          responsive
          class="type-relative"
          :per-page="inputsnode.perPage"
          :current-page="inputsnode.currentPage"
          :items="listWarehouse"
          :fields="fields"
          :filter="searchTerm"
          show-empty
          @filtered="onFiltered"
        >
          <template #cell(button)="data">
            <div class="d-grid d-md-block">
              <button
                class="btn btn-warning mr-1"
                type="button"
                @click="openModalEdit(data.item)"
              >
                แก้ไข
              </button>
              <button
                class="btn btn-danger mr-1"
                type="button"
                @click="removeWarehouse(data.item._id)"
              >
                ลบ
              </button>
            </div>
          </template>
          <template #empty>
            <div class="col-md-12 text-center m-1">
              <h4>ไม่พบข้อมูลคลังสินค้า</h4>
            </div>
          </template>
          <template #emptyfiltered>
            <div class="col-md-12 text-center m-1">
              <h4>ไม่พบข้อมูลคลังสินค้า</h4>
            </div>
          </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0 mt-2">

          <b-form-group
            v-if="totalItem > 0"
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="inputsnode.perPage"
              size="sm"
              inline
              :options="inputsnode.pageOptions"
            />
          </b-form-group>

          <div v-if="totalItem > 0">
            <b-pagination
              v-model="inputsnode.currentPage"
              :total-rows="inputsnode.totalRows"
              :per-page="inputsnode.perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </div>

    </div>
    <b-modal
      id="modal-center"
      ref="my-modal"
      v-model="IsopenModal"
      hide-footer
      centered
      header-bg-variant="primary"
    >
      <validation-observer ref="warehouseRules">
        <b-row>
          <b-col
            cols="12"
          >

            <b-form-group
              label="ชื่อคลัง"
              label-cols-md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  id="h-warehouse-name"
                  v-model="input.name"
                  :state="errors.length > 0 ? false:null"
                  autofocus
                />
                <small
                  v-if="errors.length > 0"
                  class="text-danger"
                >กรุณากรอกชื่อคลังสินค้า</small>
              </validation-provider>
            </b-form-group>

          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="ที่อยู่"
              label-cols-md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Address"
                rules="required"
              >
                <b-form-input
                  id="h-warehouse-address"
                  v-model="input.address"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-if="errors.length > 0"
                  class="text-danger"
                >กรุณากรอกที่อยู่คลังสินค้า</small>
              </validation-provider>
            </b-form-group>

          </b-col>
          <b-col
            cols="12"
          >

            <b-form-group
              label="เบอร์โทรศัพท์"
              label-for="h-first-name"
              label-cols-md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="telephoneNumber"
                rules="required"
              >
                <b-form-input
                  id="h-warehouse-telephoneNumber"
                  v-model="input.telephoneNumber"
                  type="number"
                  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-if="errors.length > 0"
                  class="text-danger"
                >กรุณากรอกเบอร์โทรศัพท์</small>
              </validation-provider>
            </b-form-group>
          </b-col></b-row>
      </validation-observer>
      <div class="d-flex justify-content-end">
        <div class="d-grid d-md-block">
          <b-button
            v-if="input._id === ''"
            variant="primary"
            class="btn mr-1"
            type="button"
            @click="addWarehouse"
          >
            ยืนยัน
          </b-button>
          <b-button
            v-if="input._id !== ''"
            class="btn btn-warning mr-1"
            type="button"
            @click="EditWarehouse"
          >
            แก้ไข
          </b-button>
          <b-button
            class="btn btn-danger mr"
            type="button"
            @click="hideModal"
          >
            ยกเลิก
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BTable, BFormGroup, BFormSelect, BPagination, BModal, BButton, VBModal, BCardBody, BFormInput,
  BForm
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default defineComponent({
  name: 'store',
  components: {
    BRow,
    BCol,
    BTable,
    BButton,
    BFormSelect,
    BPagination,
    BCardBody,
    BFormGroup,
    BCardCode,
    BModal,
    VBModal,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    required
  },
  watch: {
  },
  data () {
    return {
      IsopenModal: false,
      input: {
        _id: '',
        name: '',
        address: '',
        telephoneNumber: ''
      },
      searchTerm: '',
      fields: [
        {
          key: 'code', label: 'รหัสคลัง', tdClass: 'text-center', thClass: 'text-center', thStyle: 'min-width: 165px', sortable: true
        },
        {
          key: 'name', label: 'ชื่อคลัง', thStyle: 'min-width: 200px'
        },
        {
          key: 'address', label: 'ที่อยู่', thStyle: 'min-width: 200px'
        },
        { key: 'telephoneNumber', label: 'เบอร์โทรติดต่อ', thStyle: 'min-width: 150px' },
        { key: 'button', label: '', thStyle: 'min-width: 220px' }

      ],

      inputsnode: {
        perPage: 10,
        pageOptions: [10, 25, 50],
        totalRows: 0,
        itemsPerPage: 10,
        currentPage: 1,
        startItem: 0,
        endItem: 10
      }
    }
  },
  computed: {
    listWarehouse () {
      return this.$store.state.warehouse.listWarehouse
    },
    totalItem () {
      return this.$store.state.warehouse.listWarehouse.length
    }
  },
  methods: {
    loadWarehouse () {
      this.$store.dispatch('warehouse/getWareHouse')
        .then(response => {
          this.inputsnode.totalRows = response.data.totalItem
        }).catch(error => {
          console.log(error)
        })
    },

    addWarehouse () {
      this.$refs.warehouseRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('warehouse/AddWareHouse', this.input)
            .then(response => {
              if (response.data.success) {
                this.hideModal()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ลูกค้า',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: response.data.message
                  }
                })
                this.clearInput()
                this.loadWarehouse()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ลูกค้า',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: response.data.message
                  }
                })
              }
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ลูกค้า',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.message
                }
              })
            })
        }
      })
    },
    EditWarehouse () {
      this.$refs.warehouseRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('warehouse/editWareHouse', this.input)
            .then(response => {
              if (response.data.success) {
                this.hideModal()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ลูกค้า',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: response.data.message
                  }
                })
                this.clearInput()
                this.loadWarehouse()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ลูกค้า',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: response.data.message
                  }
                })
              }
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ลูกค้า',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: error.message
                }
              })
            })
        }
      })
    },
    removeWarehouse (id) {
      if (id !== '' && id !== undefined) {
        this.$swal({
          title: 'คุณต้องการจะลบข้อมูลนี้?',
          text: 'คุณมั่นใจรึเปล่า ? คุณจะไม่สามารถนำข้อมูลนี้กลับมาได้!',
          type: 'warning',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn-success mr-1',
          confirmButtonText: 'ยืนยัน',
          cancelButtonClass: 'btn-danger',
          cancelButtonText: 'ยกเลิก'
        }).then(result => {
          if (result.value) {
            this.$store.dispatch('warehouse/DeleteWareHouse', { id })
              .then(response => {
                if (response.data.success) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'ลูกค้า',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: response.data.message
                    }
                  })
                  this.loadWarehouse()
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'ลูกค้า',
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: response.data.message
                    }
                  })
                }
              }).catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'ลูกค้า',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: error.message
                  }
                })
              })
          }
        })
      }
    },
    openModal () {
      this.input._id = ''
      this.input.name = ''
      this.input.address = ''
      this.input.telephoneNumber = ''
      this.IsopenModal = true
    },
    openModalEdit (item) {
      if (!this.IsopenModal) {
        this.setInput(item)
        this.IsopenModal = true
      }
    },
    onFiltered (filteredItems) {
      this.inputsnode.totalRows = filteredItems.length
      this.inputsnode.currentPage = 1
    },
    hideModal () {
      this.IsopenModal = false
      this.clearInput()
    },
    clearInput () {
      this.input._id = ''
      this.input.name = ''
      this.input.address = ''
      this.input.telephoneNumber = ''
    },
    setInput (item) {
      this.input._id = item._id
      this.input.name = item.name
      this.input.address = item.address
      this.input.telephoneNumber = item.telephoneNumber
    }
  },
  created () {
    this.loadWarehouse()
  }
})
</script>
